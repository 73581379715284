import { type ComputedRef, computed } from "vue";
import type { IYaSlotConfig } from "@magnit/core/src/containers/VYaSlot/VYaSlot.types";

interface IYaSlotSlide {
  slotConfig: IYaSlotConfig[];
  name: symbol;
}

type IProductsItemsWithYaSlot = Array<any | IYaSlotSlide>;

const useProductsListWithYaSlot = (
  products: ComputedRef<any[]>,
  ysSlotConfig: IYaSlotConfig,
  yaSlotPosition: number,
  listLimit?: number,
  displayWithError?: boolean,
) => {
  const slotApi = useSlotsStore();
  const hasYaSlot = computed(
    () => slotApi.slotStatus === IYaSlotApiStatus.SUCCESS,
  );
  return computed(() => {
    const storedProducts = [...products.value] as IProductsItemsWithYaSlot;
    if (hasYaSlot.value || displayWithError) {
      if (
        storedProducts[yaSlotPosition] &&
        "slotConfig" in storedProducts[yaSlotPosition]
      ) {
        return storedProducts;
      }
      storedProducts.splice(yaSlotPosition, 0, {
        slotConfig: ysSlotConfig,
        name: Symbol("yaSlotSymbol"),
      });
      if (listLimit && storedProducts.length > listLimit) {
        storedProducts.splice(-1, 1);
      }
    }
    return storedProducts;
  });
};

export default useProductsListWithYaSlot;
