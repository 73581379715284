import { defineStore } from "pinia";
import { reactive, ref } from "vue";
import type { IViewport } from "@magnit/core/types";
import type { IPublication } from "~/store/articles";
import { urls } from "~/api/config";
import { useTransport } from "~/composables/useTransport";

export const SLOT_PLACEHOLDER_IMAGE_SRC =
  "/images/product/product-slot-placeholder.webp";
export const SLOT_PLACEHOLDER_URL =
  "https://my.magnit.ru/?utm_source=cosmetic.magnit.ru&utm_campaign=SKU&utm_medium=free";

interface IContentPromoCatalogCategory {
  disclaimer: string;
  imageUrl: string;
  isActive: true;
  labelText: string;
  labelType: number; // 1 - nope, 2 - sale, 3 - text
  title: string;
  titleType: number; // 1- black, 2 - white
  offerUrl: string;
  position: number;
  promoEndDate: string;
  promoStartDate: string;
  publicImageUrl: string;
  showEndDate: string;
  showStartDate: string;
}

interface IContentPromoCatalog {
  promosAndDiscounts: IContentPromoCatalogCategory[];
}

interface IContentStatus {
  catalog: "initial" | "pending" | "success" | "error";
  mainPage: "initial" | "pending" | "success" | "error";
  cookie: "initial" | "pending" | "success" | "error";
}

export enum ProductCatalogType {
  Promo = 1,
  Main = 2,
}

export interface ISlotAnalyticsOptions {
  promo_id?: string;
  dest?: string;
}

interface ISlotPayload {
  from: IViewport;
  to: IViewport;
  id: string;
  p1: string;
  p2: string;
}

export interface ISlot {
  payload: [ISlotPayload, ISlotPayload, ISlotPayload];
  disclaimer?: string;
}

export interface IBrandResponse {
  disclaimer: string;
  imageUrl: string;
  isActive: boolean;
  offerUrl: string;
  position: number;
  promoEndDate: string;
  promoStartDate: string;
  publicImageUrl: string;
  showEndDate: string;
  showStartDate: string;
  title: string;
}

export interface IPrivateLabelResponse {
  disclaimer: string;
  imageUrl: string;
  isActive: boolean;
  offerUrl: string;
  position: number;
  promoEndDate: string;
  promoStartDate: string;
  publicImageUrl: string;
  showEndDate: string;
  showStartDate: string;
  title: string;
}

export interface IPresentBoxResponse {
  description: string;
  disclaimer: string;
  imageUrl: string;
  isActive: boolean;
  offerUrl: string;
  position: number;
  promoEndDate: string;
  promoStartDate: string;
  publicImageUrl: string;
  showEndDate: string;
  showStartDate: string;
  title: string;
}

export interface ISpecialOfferResponse {
  description: string;
  disclaimer: string;
  imageUrl: string;
  isActive: boolean;
  offerUrl: string;
  position: number;
  promoEndDate: string;
  promoStartDate: string;
  publicImageUrl: string;
  showEndDate: string;
  showStartDate: string;
  slideType: number;
  title: string;
}

export interface ITickerResponse {
  disclaimer: string;
  imageUrl: string;
  isActive: boolean;
  offerUrl: string;
  promoEndDate: string;
  promoStartDate: string;
  publicImageUrl: string;
  showEndDate: string;
  showStartDate: string;
  title?: string;
}

export interface ICategorySectionResponse {
  adfoxPayload?: never;
  categoryId?: number;
  categoryType: ProductCatalogType;
  disclaimer?: string;
  title: string;
}

export interface ICatalogSectionItemResponse {
  categoryId?: number;
  categoryType: ProductCatalogType;
  imageUrl: string;
  name: string;
  position: number;
  publicImageUrl: string;
}

export interface IContentMainPageResponse {
  brands: IBrandResponse[];
  exclusiveWithUs: IPrivateLabelResponse[];
  giftPackages: IPresentBoxResponse[];
  specialOffers: ISpecialOfferResponse[];
  ticker: ITickerResponse;
  publications: IPublication[];
  adfox: ISlot[];
  newArrival?: ICategorySectionResponse;
  popularNow?: ICategorySectionResponse;
  categories?: ICatalogSectionItemResponse[];
}

interface IContentCookieResponse {
  cookie: {
    isActive: boolean;
    text: string;
  };
}

export const useContentStore = defineStore("content", () => {
  const catalog = ref<IContentPromoCatalog>();
  const mainPage = ref<IContentMainPageResponse>();
  const cookie = ref<IContentCookieResponse>();

  const status = reactive<IContentStatus>({
    catalog: "initial",
    mainPage: "initial",
    cookie: "initial",
  });

  async function requestCatalog() {
    status.catalog = "pending";

    const { data, error } = await useTransport<IContentPromoCatalog>(
      urls.content.catalog,
      {
        method: "GET",
      },
    );

    if (data.value) {
      catalog.value = data.value;
      status.catalog = "success";
    }

    if (error.value) {
      status.catalog = "error";
    }
  }

  async function requestMainPage() {
    status.mainPage = "pending";

    const { data, error } = await useTransport<IContentMainPageResponse>(
      urls.content.mainPage,
      {
        method: "GET",
      },
    );

    if (data.value) {
      mainPage.value = data.value;
      status.mainPage = "success";
    }

    if (error.value) {
      status.mainPage = "error";

      throw createError({
        statusCode: error.value.statusCode,
        message: error.value.data?.message || "Error main-page request",
        fatal: true,
      });
    }
  }

  function mainPageAdfoxSplice(index: number, count: number) {
    mainPage.value?.adfox.splice(index, count);
  }

  return {
    requestCatalog,
    catalog,
    requestMainPage,
    mainPage,
    cookie,
    status,
    mainPageAdfoxSplice,
  };
});
